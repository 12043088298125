import React from 'react'
import './Courses.css'

const Courses = () => {
  return (
    <div className='courses-section'>
        <div className="resource">
            <div className="notes rv">
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repudiandae, amet!</p>
                <button className='rv-button'>Notes</button>
            </div>
            <div className="problems rv">
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repudiandae, amet!</p>
                <button className='rv-button'>Problems</button>
            </div>
            <div className="subjects rv">
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repudiandae, amet!</p>
                <button className='rv-button'>Subjects</button>
            </div>
            <div className="doubts rv">
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repudiandae, amet!</p>
                <button className='rv-button'>doubts</button>
            </div>
        </div>
    </div>
  )
}

export default Courses