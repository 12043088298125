import React from 'react'
import Footer from '../Footer/Footer';
import './Contact.css';

const Contact = () => {
  return (
    <>
      <div className="contact-section" id="contact">
        <h2 className="section__title">Get in Touch</h2>

        <div className="contact__container grid">
          <div className="contact__info">
            <h3 className="contact__title">Let's talk.. I'm all in ears.</h3>
            <p className="contact__details">Don't like forms? Send me an email.</p>
          </div>

          <form action="" className="contact__form">
            <div className="contact__form-group">
              <div className="contact__form-div">
                <input type="text" className="contact__form-input" placeholder='Your Name' />
              </div>
              <div className="contact__form-div">
                <input type="email" className="contact__form-input" placeholder='Your E-mail' />
              </div>
            </div>
            <div className="contact__form-div">
              <input type="text" className="contact__form-input" placeholder='Subject' />
            </div>

            <div className="contact__form-div contact__form-area">
              <textarea name="" id="" cols="30" rows="10" className='contact__form-input' placeholder='Write your message'></textarea>
            </div>

            <button className='button'>Send Message</button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Contact