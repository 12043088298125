import ToggleVisibility from "../LinkedList/ToggleVisibilityArray";
import LinkedList from './LinkedList'
import "../../Paths/Problems.css";

export default function App() {
  return (
    <div className="App">
      <ToggleVisibility>
        <LinkedList/>
      </ToggleVisibility>
    </div>
  );
}