import React from "react";
import Footer from "../Footer/Footer";
import "./Blogs.css";

const Blogs = () => {
  let blogs = [
    {
      title: "Love Babbar",
      desc: "lodkfvnfvaflfknvfkvnonvoeinarrn'oknfvoknvadkn",
      link: "https://hackerrank.com",
    },
    {
      title: "Love Babbar",
      desc: "lodkfvnfvaflfknvfkvnonvoeinarrn'oknfvoknvadkn",
      link: "https://hackerrank.com",
    },
    {
      title: "Love Babbar",
      desc: "lodkfvnfvaflfknvfkvnonvoeinarrn'oknfvoknvadkn",
      link: "https://hackerrank.com",
    },
    {
      title: "Love Babbar",
      desc: "lodkfvnfvaflfknvfkvnonvoeinarrn'oknfvoknvadkn",
      link: "https://hackerrank.com",
    },
    {
      title: "Love Babbar",
      desc: "lodkfvnfvaflfknvfkvnonvoeinarrn'oknfvoknvadkn",
      link: "https://hackerrank.com",
    },
    {
      title: "Love Babbar",
      desc: "lodkfvnfvaflfknvfkvnonvoeinarrn'oknfvoknvadkn",
      link: "https://hackerrank.com",
    },
    {
      title: "Love Babbar",
      desc: "lodkfvnfvaflfknvfkvnonvoeinarrn'oknfvoknvadkn",
      link: "https://hackerrank.com",
    },
  ];
  return (
    <div className="sheet-section">
      <div className="sheet-desc">
        <h1 className="sheet-head">Lorem ipsum dolor sit amet.</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque alias
          nobis impedit! Inventore, deleniti dolorum maxime repellendus
          reprehenderit natus voluptatem? ipsum, dolor sit amet consectetur
          adipisicing elit. Eum, in!
        </p>
      </div>
      <div className="sheets">
        <div className="p1">
          <div className="s1">
            <h2>{blogs[0].title}</h2>
            <p className="sheetdesc">{blogs[0].desc}</p>
            <button className="sheet-btn">
              {" "}
              <a href={blogs[0].link}>Get Now</a>
            </button>
          </div>
          <div className="s1">
            <h2>{blogs[0].title}</h2>
            <p className="sheetdesc">{blogs[0].desc}</p>
            <button className="sheet-btn">
              {" "}
              <a href={blogs[0].link}>Get Now</a>
            </button>
          </div>
          <div className="s1">
            <h2>{blogs[0].title}</h2>
            <p className="sheetdesc">{blogs[0].desc}</p>
            <button className="sheet-btn">
              {" "}
              <a href={blogs[0].link}>Get Now</a>
            </button>
          </div>
          <div className="s1">
            <h2>{blogs[0].title}</h2>
            <p className="sheetdesc">{blogs[0].desc}</p>
            <button className="sheet-btn">
              {" "}
              <a href={blogs[0].link}>Get Now</a>
            </button>
          </div>
        </div>
        <div className="p1">
          <div className="s1">
            <h2>{blogs[0].title}</h2>
            <p className="sheetdesc">{blogs[0].desc}</p>
            <button className="sheet-btn">
              {" "}
              <a href={blogs[0].link}>Get Now</a>
            </button>
          </div>
          <div className="s1">
            <h2>{blogs[0].title}</h2>
            <p className="sheetdesc">{blogs[0].desc}</p>
            <button className="sheet-btn">
              {" "}
              <a href={blogs[0].link}>Get Now</a>
            </button>
          </div>
          <div className="s1">
            <h2>{blogs[0].title}</h2>
            <p className="sheetdesc">{blogs[0].desc}</p>
            <button className="sheet-btn">
              {" "}
              <a href={blogs[0].link}>Get Now</a>
            </button>
          </div>
          <div className="s1">
            <h2>{blogs[0].title}</h2>
            <p className="sheetdesc">{blogs[0].desc}</p>
            <button className="sheet-btn">
              {" "}
              <a href={blogs[0].link}>Get Now</a>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blogs;
