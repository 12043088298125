import React, { useState } from "react";

export default function ToggleVisibility({ children }) {

  // React state to manage visibility
  const [show, setShow] = useState();

  // function to toggle the boolean value
  function toggleShow() {
    setShow(!show);
  }
  let buttonText = show ? <h3 className="ptr">10. Hashing <i className='fa-solid fa-caret-up'></i></h3> :<h3 className="ptr">10. Hashing <i className='fa-solid fa-caret-down'></i></h3>;

  return (
    <div className="component-container">
      <div className="btn-menu" onClick={toggleShow}>{buttonText}</div>
      {show && children}
    </div>
  );
}