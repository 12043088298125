import ToggleVisibility from "./ToggleVisibilityGraphs";
import Graphs from './Graphs'
import "../../Paths/Problems.css";

export default function App() {
  return (
    <div className="App">
      <ToggleVisibility>
        <Graphs/>
      </ToggleVisibility>
    </div>
  );
}