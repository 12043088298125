import React from 'react'
import Footer from '../Footer/Footer'
import './Sheet.css'

const Sheet = () => {
  let sheets = [
    {
      title: "Love Babbar DSA Cracker Sheet",
      desc: "Download and get this ultimate Love Babbar DSA Cracker Sheet containing 450 Questions related to Data structures and Algorithms. This sheet is proven to be very helpful for many students and has helped them crack many coding rounds and interviews.",
      link: "https://github.com"
    },
    {
      title: "DSA Sheet by Fraz",
      desc: "Here is a collection of problems from Mohammad Fraz sheet using which people have cracked their dream jobs. These questions are commonly asked in product-based companies like Amazon, Microsoft, Google, etc.",
      link: "https://github.com"
    },
    {
      title: "Apna College 375 Qs DSA Sheet",
      desc: "Download and get this ultimate Love Babbar DSA Cracker Sheet containing 450 Questions related to Data structures and Algorithms. This sheet is proven to be very helpful for many students and has helped them crack many coding rounds and interviews.",
      link: "https://github.com"
    },
    {
      title: "Nishant Chahar SDE Sheet",
      desc: "Here is a collection of problems from Nishant Chahar sheet using which people have cracked their dream jobs. These questions are commonly asked in product-based companies like Amazon, Microsoft,Google etc.",
      link: "https://github.com"
    },
    {
      title: "Striver’s SDE Sheet",
      desc: "Check out the Most Asked Coding Interview Problem list compiled by Raja Vikramaditya AKA Striver. The sheet is popularly known as “Striver’s SDE Sheet” . This sheet contains 250 questions.",
      link: "https://github.com"
    },
    {
      title: "Strivers A2Z DSA Course/Sheet",
      desc: "The course is made for people who want to learn DSA from A to Z for FREE from one of India’s top educators in DSA. Ready to go and start from today!",
      link: "https://github.com"
    },
    {
      title: "Siddharth Singh Coding Sheet",
      desc: "Here is a collection of problems from Siddharth Singh sheet using which people have cracked their dream jobs. These questions are commonly asked in product-based companies like Amazon, Microsoft,Google etc.",
      link: "https://github.com"
    },
    {
      title: "DSA Sheet by Arsh Goyal",
      desc: "Download and get this ultimate Love Babbar DSA Cracker Sheet containing 450 Questions related to Data structures and Algorithms. This sheet is proven to be very helpful for many students and has helped them crack many coding rounds and interviews.",
      link: "https://github.com"
    }
  ]
  return (
    <div className="sheet-section">
      <div className="sheet-desc">
        <h1 className="sheet-head">Compelete Preperation DSA Sheet.</h1>
        <p>Here are best DSA preperation kit or sheets given below which are given by Trusted and Worthy Teachers. </p>
      </div>
      <div className="sheets">
        <div className="p1">
          <div className="s1">
            <h2>{sheets[0].title}</h2>
            <p className="sheetdesc">{sheets[0].desc}</p>
            <button className='sheet-btn'><a href={sheets[0].link}></a> Get Now</button>
          </div>
          <div className="s1">
            <h2>{sheets[1].title}</h2>
            <p className="sheetdesc">{sheets[1].desc}</p>
            <button className='sheet-btn'><a href={sheets[1].link}></a> Get Now</button>
          </div>
          <div className="s1">
            <h2>{sheets[2].title}</h2>
            <p className="sheetdesc">{sheets[2].desc}</p>
            <button className='sheet-btn'><a href={sheets[2].link}></a> Get Now</button>
          </div>
          <div className="s1">
            <h2>{sheets[3].title}</h2>
            <p className="sheetdesc">{sheets[3].desc}</p>
            <button className='sheet-btn'><a href={sheets[3].link}></a> Get Now</button>
          </div>
        </div>

        <div className="p1">
          <div className="s1">
            <h2>{sheets[4].title}</h2>
            <p className="sheetdesc">{sheets[4].desc}</p>
            <button className='sheet-btn'><a href={sheets[4].link}></a> Get Now</button>
          </div>
          <div className="s1">
            <h2>{sheets[5].title}</h2>
            <p className="sheetdesc">{sheets[5].desc}</p>
            <button className='sheet-btn'><a href={sheets[5].link}></a> Get Now</button>
          </div>
          <div className="s1">
            <h2>{sheets[6].title}</h2>
            <p className="sheetdesc">{sheets[6].desc}</p>
            <button className='sheet-btn'><a href={sheets[6].link}></a> Get Now</button>
          </div>
          <div className="s1">
            <h2>{sheets[7].title}</h2>
            <p className="sheetdesc">{sheets[7].desc}</p>
            <button className='sheet-btn'><a href={sheets[7].link}></a> Get Now</button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Sheet