import React from 'react'
import Hero from '../Hero/Hero'
import './Home.css'
import tricolor from '../img/tricolor.png'
import WebDes from '../WebDes/WebDes'
import Footer from '../Footer/Footer'
import Courses from '../Courses/Courses'

const Home = () => {
  return (
    <>
      <section className='home-section'>
        <div className='home-bar'>
          <Hero />
          <div className="image">
            <img src={tricolor} alt="Avatar" />
          </div>
        </div>
      </section>
      <WebDes />
      <Courses/>
      <Footer/>
    </>
  )
}
export default Home