import ToggleVisibility from "./ToggleVisibilityQueue";
import Queue from './Queue'
import "../../Paths/Problems.css";

export default function App() {
  return (
    <div className="App">
      <ToggleVisibility>
        <Queue/>
      </ToggleVisibility>
    </div>
  );
}