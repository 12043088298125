import ToggleVisibility from "./ToggleVisibilityArray";
import Sorting from './Sorting'
import "../../Paths/Problems.css";

export default function App() {
  return (
    <div className="App">
      <ToggleVisibility>
        <Sorting/>
      </ToggleVisibility>
    </div>
  );
}