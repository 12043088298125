import React from 'react'
import './Hero.css'

const Hero = () => {
    return (
        <div>
            <div className="hero">
                <div className="hero-div">
                    <h1 className='heading'>
                        <p className='heading-two'>Welcome to <span className='title'>CodeHustlers</span> by Vivek</p><br />
                    </h1>
                    <p className="description">
                        Want to improve your programming skills?
                        Learn Data Structure and Algorithms by practicing all patterns of questions with the help of video lectures and clean articles.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Hero