import React from 'react'
import Footer from '../Footer/Footer'
import ToggleArray from '../Topics/Array/ToggleArrray'
import ToggleRecursion from '../Topics/Recursion/ToggleRecursion'
import ToggleLinkedList from '../Topics/LinkedList/ToggleLinkedList'
import ToggleGraphs from '../Topics/Graphs/ToggleGraphs'
import ToggleHashing from '../Topics/Hashing/ToggleHashing'
import ToggleBinaryTree from '../Topics/BinaryTree/ToggleBinaryTree'
import ToggleString from '../Topics/String/ToggleString'
import ToggleStack from '../Topics/Stack/ToggleStack'
import ToggleQueue from '../Topics/Queue/ToggleQueue'
import ToggleSorting from '../Topics/Sorting/ToggleSorting'
import './Paths.css'

const Paths = () => {
    return (
        <>
            <div className='paths-section'>
                <div className="upper">
                    <h1 className="main-head">
                        Let's Start DSA from today!
                    </h1>
                    <p>These articles are easy. I try to keep things in a way that you don’t get stuck. I have kept my lectures in a very organized manner. Each lecture has:</p>
                </div>
                <div className="topics">
                    <div className='array'><ToggleArray/></div>
                    <div className='string'><ToggleString/></div>         
                    <div className='recursion'><ToggleRecursion/></div>
                    <div className='linkedlist'><ToggleLinkedList/></div>
                    <div className='stack'><ToggleStack/></div>
                    <div className='queue'><ToggleQueue/></div>
                    <div className='sorting'><ToggleSorting/></div>
                    <div className='binarytree'><ToggleBinaryTree/></div>
                    <div className='graphs'><ToggleGraphs/></div>
                    <div className='hashing'><ToggleHashing/></div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Paths