import ToggleVisibility from "../BinaryTree/ToggleVisibilityBinaryTree";
import BinaryTree from '../BinaryTree/BinaryTree'
import "../../Paths/Problems.css";

export default function App() {
  return (
    <div className="App">
      <ToggleVisibility>
        <BinaryTree/>
      </ToggleVisibility>
    </div>
  );
}