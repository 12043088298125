import React from 'react'
import './Footer.css'
import { Link } from "react-router-dom";
// import logo from "../img/logo.png"
import logo from '../img/codehustlers.png'

const Footer = () => {
    return (
        <div className="footer-section">
            <div className="footer-container">
                <div className="footer-left">
                    <Link to="/Home" className="footer-link">
                        <img src={logo} alt="logo" />
                        <div className="logo">CodeHustlers</div>
                    </Link>
                    <p className="copyright">Copyright © DeveVivek 2022.
                    </p>
                </div>
                <div className="footer-middle">
                    <div className="v1">
                        <h2 className='product'>Code Hustlers</h2>
                        <div className="items">
                            <p>About Us</p>
                            <p>Privacy Policy</p>
                            <p>Terms & Conditions</p>
                            <p>Bug Bounty</p>
                        </div>
                    </div>
                    <div className="v2">
                        <h2 className='product'>Products</h2>
                        <div className="items">
                            <p>Courses</p>
                            <p>Try for free</p>
                            <p>Career Camp</p>
                            <p>Mock Interviews</p>
                        </div>
                    </div>
                    <div className="v3">
                        <h2 className='product'>Community</h2>
                        <div className="items">
                            <p>Blog</p>
                            <p>Events</p>
                            <p>Affiliate</p>
                            <p>DevPost</p>
                        </div>
                    </div>
                </div>
                <div className="footer-right">
                    <h2 className="product">Follow us on</h2>
                    <div className="links">
                        <a href="/" className="home__social-link" target="_blank">
                            <i className="fa-brands fa-instagram"></i>
                        </a>
                        <a href="/" className="home__social-link" target="_blank">
                            <i className="fa-brands fa-twitter"></i>
                        </a>
                        <a href="/" className="home__social-link" target="_blank">
                            <i className="fa-brands fa-github"></i>
                        </a>
                        <a href="/" className="home__social-link" target="_blank">
                            <i className="fa-brands fa-linkedin"></i>
                        </a>
                        <a href="/" className="home__social-link" target="_blank">
                            <i className="fa-brands fa-medium"></i>
                        </a>
                    </div>
                    <div className="contact">
                        <h2 className='product contact'>CONTACT US</h2>
                        <div className="call"><i className="fa-solid fa-phone"></i><span className="footer-font">9027456635</span></div>
                        <div className="msg "><i className=" fa-solid fa-envelope"></i><soan className="footer-font">codexvivek@gmail.com</soan></div>
                    </div>
                </div>
                <div className="last"><p className="copyright">Copyright © DeveVivek 2022.
                </p></div>
            </div>
        </div>
    )
}

export default Footer