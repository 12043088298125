import ToggleVisibility from "../Hashing/ToggleVisibilityHashing";
import Hashing from '../Hashing/Hashing'
import "../../Paths/Problems.css";

export default function App() {
  return (
    <div className="App">
      <ToggleVisibility>
        <Hashing/>
      </ToggleVisibility>
    </div>
  );
}