const navbarItems = [
  {
    title: "01. Home",
    link: "/Home",
  },
  {
    title: "02. DSA Sheet",
    link: "/Sheet",
  },
  {
    title: "03. Guided Paths",
    link: "/Paths",
  },
  {
    title: "04. Blogs",
    link: "/Blogs",
  },
  {
    title: "05. Contact",
    link: "/Contact",
  },
];

export default navbarItems;