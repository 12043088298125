import React, { useEffect, useRef } from 'react'
import { init } from 'ityped'
import './WebDes.css'
import me from "../img/me1.jpg"

const WebDes = () => {
  const textRef = useRef();
  console.log(textRef);

  useEffect(() => {
    init(textRef.current, {
      showCursor: true,
      backDelay: 2000,
      backSpeed: 60,
      strings: ["Codes in C++, Java, and Python", "Practice questions", "Time and space complexity analysis", "Notes & Resources"]
    })
  }, [])

  return (
    <>
      <section className='WebDes-section'>
        <div className="WebDes-bar">
          <div className="me">
            <img src={me} alt="MySelf" />
          </div>
          <div className="about-me">
            <div className="title-me">Vivek Kumar</div>
            <div className="achieve">Founder of CodeHustlers | Full-Stack Developer | Competitve Coder | 50+ projects @GitHub | Open Source Contributer</div>
            <div className="web-desc">I love teaching. I have made this website to make DSA easy and fun to learn. So here we have very clean and organized lectures where each lecture contains the practice question, notes, and a very nice video. Enjoy learning.</div>
          </div>
        </div>
      </section>
      <div className="letter">
        <div className="left">
          <div className="head-left"> <strong> Want To Learn Data Structure & <br /> Algorithms in a fun way?</strong></div>
          <button className='DSA' > Learn DSA </button>
        </div>
        <div className="right">
          <div className="head-right"><strong>Learn With</strong></div>
          <div className="langs">
            <strong><span ref={textRef}></span></strong>
          </div>
        </div>
      </div>
    </>
  )
}
export default WebDes