import ToggleVisibility from "../Array/ToggleVisibilityArray";
import Array from '../Array/Array'
import "../../Paths/Problems.css";

export default function App() {
  return (
    <div className="App">
      <ToggleVisibility>
        <Array title="1. Array"/>
      </ToggleVisibility>
    </div>
  );
}