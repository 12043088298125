import ToggleVisibility from "./ToggleVisibilityString";
import String from './String'
import "../../Paths/Problems.css";

export default function App() {
  return (
    <div className="App">
      <ToggleVisibility>
        <String/>
      </ToggleVisibility>
    </div>
  );
}