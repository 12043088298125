import React from 'react'
import '../../Paths/Problems.css';
import leetcode from '../../img/leetcode.png'
import youtube from '../../img/youtube.png'
import gfg from '../../img/GFG.png'

const data = [
    {index: "1.", problem: "Sort an Array", youtube:<a href="/"><img src={youtube} alt="youtube" /></a>, link1: <a href="/"><img src={leetcode} alt="leetcode" /></a>, link2: <a href="/"><img src={gfg} alt="geeksforgeeks" /></a> },
    {index: "2.", problem: "Reverse an Array", youtube:<a href="/"><img src={youtube} alt="youtube" /></a>, link1: <a href="/"><img src={leetcode} alt="leetcode" /></a>, link2: <a href="/"><img src={gfg} alt="geeksforgeeks" /></a> },
    {index: "3.", problem: "Rotate an Array", youtube:<a href="/"><img src={youtube} alt="youtube" /></a>, link1: <a href="/"><img src={leetcode} alt="leetcode" /></a>, link2: <a href="/"><img src={gfg} alt="geeksforgeeks" /></a> },
    {index: "4.", problem: "Check Palindrome", youtube:<a href="/"><img src={youtube} alt="youtube" /></a>, link1: <a href="/"><img src={leetcode} alt="leetcode" /></a>, link2: <a href="/"><img src={gfg} alt="geeksforgeeks" /></a> },
    {index: "5.", problem: "Find the max & min in an array", youtube:<a href="/"><img src={youtube} alt="youtube" /></a>, link1: <a href="/"><img src={leetcode} alt="leetcode" /></a>, link2: <a href="/"><img src={gfg} alt="geeksforgeeks" /></a> },
    {index: "6.", problem: "Find the kth max & min element of an array", youtube:<a href="/"><img src={youtube} alt="youtube" /></a>, link1: <a href="/"><img src={leetcode} alt="leetcode" /></a>, link2: <a href="/"><img src={gfg} alt="geeksforgeeks" /></a> },
    {index: "7.", problem: "Check Palindrome", youtube:<a href="/"><img src={youtube} alt="youtube" /></a>, link1: <a href="/"><img src={leetcode} alt="leetcode" /></a>, link2: <a href="/"><img src={gfg} alt="geeksforgeeks" /></a> },
    {index: "8.", problem: "Check Palindrome", youtube:<a href="/"><img src={youtube} alt="youtube" /></a>, link1: <a href="/"><img src={leetcode} alt="leetcode" /></a>, link2: <a href="/"><img src={gfg} alt="geeksforgeeks" /></a> }
]

function ValueData() {
    return (
        <div className="App">
            <table>
                <tr>
                    <th>No.</th>
                    <th>Problem</th>
                    <th>Video</th>
                    <th>Link 1</th>
                    <th>Link 2</th>
                </tr>
                {data.map((val, key) => {
                    return (
                        <tr key={key}>
                            <td className='index'>{val.index}</td>
                            <td className='question'>{val.problem}</td>
                            <td>{val.youtube}</td>
                            <td>{val.link1}</td>
                            <td>{val.link2}</td>
                        </tr>
                    )
                })}
            </table>
        </div>
    );
}

export default ValueData;